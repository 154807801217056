<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-9"
        label="Titolo finestra (Titolo)"
        v-model="point.windowTitle"
        :errorMsg="errorHash.windowTitle"
        @input="removeFieldError('windowTitle')"
      />
      <FormInputTextWidget
        class="col-lg-3"
        label="Anno"
        v-model="point.year"
        not-required
      />
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-9"
        label="Titolo particolare (Sottotitolo)"
        v-model="point.title"
        :errorMsg="errorHash.title"
        @input="removeFieldError('title')"
      />
      <FormSelectTemplateIdWidget
        class="col-lg-3"
        label="Template"
        v-model="point.templateId"
        :errorMsg="errorHash.templateId"
        @input="removeFieldError('templateId')"
      />
    </div>
    <div class="row">
      <FormSelectColourWidget
        class="col-12"
        label="Colore"
        v-model="point.colour"
        :errorMsg="errorHash.colour"
        @input="removeFieldError('colour')"
      />
    </div>
    <div class="row">
      <FormInputCheckboxWidget
        class="col-4"
        v-model="point.hasContours"
        not-required
      >Sono attivi i contorni</FormInputCheckboxWidget>
    </div>

    <div class="row">
      <div class="col-12 mt-4 d-flex justify-content-between">
        <div>
          <button class="btn btn-outline-danger font-weight-bold" @click.prevent="removePoint" v-if="edit">Cancella segnaposto</button>
        </div>
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '../../../libs/Form/mixins/validate';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';

export default {
  name: 'point-form',
  mixins: [validateMixin, confirmWithModalMixin],
  components: {
    FormContainer: () => import('@/views/components/Form/FormContainer'),
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert'),
    FormInputTextWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormInputCheckboxWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputCheckboxWidget'),
    FormSelectTemplateIdWidget: () => import('./Widgets/SelectTemplateIdWidget.vue'),
    FormSelectColourWidget: () => import('./Widgets/SelectColourWidget.vue'),
  },
  props: {
    point: {
      type: Object,
      default: () => ({
        title: null,
        year: null,
        hasContours: false,
        templateId: 1,
        windowTitle: null,
        colour: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.point.windowTitle) {
        this.addError('Inserisci il titolo', 'windowTitle');
      }

      if (!this.point.title) {
        this.addError('Inserisci il sottotitolo', 'title');
      }

      if (!this.point.templateId) {
        this.addError('Seleziona il template', 'templateId');
      }

      if (!this.point.colour) {
        this.addError('Seleziona il colore', 'colour');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      const pointToReturn = { ...this.point };

      this.$emit('submitForm', pointToReturn);
    },
    removePoint () {
      this.confirm('Cancellare il segnaposto e tutte le note collegate?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removePoint(this.point.id)
            .then(() => {
              this.$router.replace({ name: 'modules.show' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
  },
};

</script>
